:root {
  --intelibly-branding-error-100: rgba(247, 236, 241, 1);
  --intelibly-branding-error-200: rgba(240, 218, 229, 1);
  --intelibly-branding-error-300: rgba(228, 189, 208, 1);
  --intelibly-branding-error-400: rgba(211, 147, 177, 1);
  --intelibly-branding-error-50: rgba(250, 245, 248, 1);
  --intelibly-branding-error-500: rgba(194, 114, 150, 1);
  --intelibly-branding-error-600: rgba(161, 78, 111, 1);
  --intelibly-branding-error-700: rgba(147, 67, 96, 1);
  --intelibly-branding-error-800: rgba(123, 57, 81, 1);
  --intelibly-branding-error-900: rgba(104, 51, 70, 1);
  --intelibly-branding-error-950: rgba(61, 26, 38, 1);
  --intelibly-branding-neutral-100: rgba(242, 243, 245, 1);
  --intelibly-branding-neutral-200: rgba(231, 232, 237, 1);
  --intelibly-branding-neutral-300: rgba(211, 211, 223, 1);
  --intelibly-branding-neutral-400: rgba(185, 185, 204, 1);
  --intelibly-branding-neutral-50: rgba(248, 249, 250, 1);
  --intelibly-branding-neutral-500: rgba(150, 149, 176, 1);
  --intelibly-branding-neutral-600: rgba(136, 133, 162, 1);
  --intelibly-branding-neutral-700: rgba(117, 114, 143, 1);
  --intelibly-branding-neutral-800: rgba(98, 95, 120, 1);
  --intelibly-branding-neutral-900: rgba(82, 79, 99, 1);
  --intelibly-branding-neutral-950: rgba(53, 52, 65, 1);
  --intelibly-branding-primary-100: rgba(246, 238, 249, 1);
  --intelibly-branding-primary-200: rgba(236, 219, 243, 1);
  --intelibly-branding-primary-300: rgba(223, 191, 232, 1);
  --intelibly-branding-primary-400: rgba(205, 153, 217, 1);
  --intelibly-branding-primary-50: rgba(251, 247, 252, 1);
  --intelibly-branding-primary-500: rgba(180, 112, 197, 1);
  --intelibly-branding-primary-600: rgba(153, 81, 168, 1);
  --intelibly-branding-primary-700: rgba(140, 71, 153, 1);
  --intelibly-branding-primary-800: rgba(105, 54, 114, 1);
  --intelibly-branding-primary-900: rgba(89, 48, 95, 1);
  --intelibly-branding-primary-950: rgba(55, 22, 60, 1);
  --intelibly-branding-secondary-100: rgba(227, 231, 246, 1);
  --intelibly-branding-secondary-200: rgba(206, 215, 239, 1);
  --intelibly-branding-secondary-300: rgba(173, 188, 227, 1);
  --intelibly-branding-secondary-400: rgba(133, 154, 213, 1);
  --intelibly-branding-secondary-50: rgba(243, 244, 251, 1);
  --intelibly-branding-secondary-500: rgba(104, 123, 201, 1);
  --intelibly-branding-secondary-600: rgba(85, 98, 187, 1);
  --intelibly-branding-secondary-700: rgba(74, 81, 171, 1);
  --intelibly-branding-secondary-800: rgba(65, 69, 140, 1);
  --intelibly-branding-secondary-900: rgba(56, 60, 112, 1);
  --intelibly-branding-secondary-950: rgba(38, 39, 69, 1);
  --intelibly-branding-success-100: rgba(228, 244, 234, 1);
  --intelibly-branding-success-200: rgba(202, 232, 212, 1);
  --intelibly-branding-success-300: rgba(161, 212, 178, 1);
  --intelibly-branding-success-400: rgba(111, 185, 136, 1);
  --intelibly-branding-success-50: rgba(243, 250, 245, 1);
  --intelibly-branding-success-500: rgba(75, 156, 103, 1);
  --intelibly-branding-success-600: rgba(58, 128, 82, 1);
  --intelibly-branding-success-700: rgba(48, 101, 67, 1);
  --intelibly-branding-success-800: rgba(42, 81, 56, 1);
  --intelibly-branding-success-900: rgba(36, 67, 47, 1);
  --intelibly-branding-success-950: rgba(15, 36, 23, 1);
  --intelibly-branding-warning-100: rgba(248, 238, 235, 1);
  --intelibly-branding-warning-200: rgba(241, 222, 218, 1);
  --intelibly-branding-warning-300: rgba(230, 194, 187, 1);
  --intelibly-branding-warning-400: rgba(215, 159, 149, 1);
  --intelibly-branding-warning-50: rgba(251, 247, 235, 1);
  --intelibly-branding-warning-500: rgba(196, 123, 111, 1);
  --intelibly-branding-warning-600: rgba(176, 93, 85, 1);
  --intelibly-branding-warning-700: rgba(145, 68, 63, 1);
  --intelibly-branding-warning-800: rgba(122, 57, 55, 1);
  --intelibly-branding-warning-900: rgba(105, 50, 50, 1);
  --intelibly-branding-warning-950: rgba(57, 25, 24, 1);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body>* {
  -webkit-overflow-scrolling: touch;
}

.gm-style .gm-style-iw-c {
  padding: 12px !important;
}

.gm-style-iw-d {
  overflow: auto;
}

@media (max-width: 599.95px) {
  .gm-bundled-control-on-bottom {
    bottom: 175px !important;
  }
}